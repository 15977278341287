window.$ = window.jQuery = require('jquery');

$(function() {
    
    
    //menu xs
    var btnMenu = $('.btn--menu-js');
    if (btnMenu.length) {
        btnMenu.on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('html').toggleClass('body-menu-active');
            // $('html').removeClass('body-search-active');
            btnSearch.removeClass('active');
        });
    }
    //end menu xs


    // add class header on scroll
    function add_class_scroll() {
        var header = $('.header--js'),
            scroll = $(window).scrollTop();
        if (scroll >= 1) {
            header.addClass('scroll')
        } else {
            header.removeClass('scroll')
        }
    }

    $(window).on('scroll', add_class_scroll);
    add_class_scroll();
    // end add class header on scroll

    // active menu and scroll to content
    var header = $('.header--js'),
        topMenu = $('.header__menu--js'),
        offset = 55,
        headerHeight = header.outerHeight()-offset,
        // all list items
        menuItems =  topMenu.find('a[href*="#"]'),
        // anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var href = $(this).attr("href"),
            id = href.substring(href.indexOf('#')),
            item = $(id);
            //console.log(item)
            if (item.length) { return item; }
        });

    // so we can get a fancy scroll animation
    menuItems.on('click', function(e) {
        var href = $(this).attr("href"),
            id = href.substring(href.indexOf('#')),
            offsetTop = href === "#" ? 0 : $(id).offset().top+10;

        if($('html').hasClass('body-menu-active')) {
            $('html').removeClass('body-menu-active');
            btnMenu.removeClass('active');
        }

        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 1000);
        e.preventDefault();
    });

    // bind to scroll
    $(window).on('scroll', function() {
        // get container scroll position
        var fromTop = $(this).scrollTop()+headerHeight;

        // get id of current scroll item
        var cur = scrollItems.map(function() {
          if ($(this).offset().top < fromTop)
            return this;
        });

        // get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        menuItems.parent().removeClass('active');
        if(id) {
            menuItems.parent().end().filter("[href*='#"+id+"']").parent().addClass('active');
        }

    });
    // end active menu and scroll to content


});
